import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Form,
  FormItem,
  Input,
  Button,
  Result,
  Message,
  Loading,
  Select,
  Option,
  Checkbox,
  MessageBox,
} from "element-ui";
import "@/style/reset.less";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Result);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.use(Loading.directive);
const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vm;
